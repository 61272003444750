<template>
  <div>
    <b-table-simple class="table" responsive borderless fixed>
      <thead>
        <tr>
          <th><Ellipsis>Tipo</Ellipsis></th>
          <th><Ellipsis>Data</Ellipsis></th>
          <th><Ellipsis>Paciente</Ellipsis></th>
          <th><Ellipsis>Valor</Ellipsis></th>
          <th><Ellipsis>Mét. recebimento</Ellipsis></th>
          <th><Ellipsis>Conta bancária</Ellipsis></th>
          <th><Ellipsis>Forma de pag.</Ellipsis></th>
          <th><Ellipsis>Descrição</Ellipsis></th>
          <th class="no-print">Conta</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bill in bills" :key="bill.id">
          <td>
            <div class="d-flex flex-row align-items-center">
              <PlusIcon
                v-if="bill?.type === 'bill_to_receive'"
                class="plus-icon mr-1"
              />
              <MinusIcon
                v-else-if="bill?.type === 'bill_to_pay'"
                class="minus-icon mr-1"
              />
              {{ getBillTypeLabel(bill.type) }}
            </div>
          </td>
          <td>{{ moment(bill.service_date).isValid() ? moment(bill.service_date).format('DD/MM/YYYY') : '-' }}</td>
          <td>{{ bill.patient?.name || '-' }}</td>
          <td>{{ getCreditFormatted(bill) }}</td>
          <td>{{ getPaymentMethods(bill) }}</td>
          <td>{{ getBankAccounts(bill) }}</td>
          <td>{{ getInstallmentsLabel(bill) }}</td>
          <td>{{ bill.description }}</td>
          <td class="no-print">
            <b-button variant="link" @click="onEdit(bill)" class="no-print">
              <EyeIcon class="eye-icon icon" v-b-tooltip="'Visualizar'"/>
            </b-button>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    EyeIcon: () => import('@/assets/icons/eye.svg'),
    MinusIcon: () => import('@/assets/icons/minus-rounded2.svg'),
    PlusIcon: () => import('@/assets/icons/plus.svg')
  },
  props: {
    bills: {
      type: Array,
      required: true
    },
    onEdit: Function
  },
  methods: {
    getBillTypeLabel(type) {
      return type === 'bill_to_receive' ? 'Entrada' : 'Saída'
    },
    getCreditFormatted(bill) {
      if (bill?.payments?.length > 1) {
        return `R$ ${parseNumberToMoney(bill.payments.reduce((acc, payment) => acc + payment.amount, 0)).trim().split('$')[1]}`
      }
      const amount = bill.amount ? bill.amount : bill.payments[0].amount
      return `R$ ${parseNumberToMoney(amount).trim().split('$')[1]}`
    },
    getPaymentMethods(bill) {
      return bill?.payments?.map(payment => payment.payment_method.name).join(', ')
    },
    getBankAccounts(bill) {
      return bill?.payments?.map(payment => (payment.bank_account ? payment.bank_account.name : '')).join(', ')
    },
    getInstallmentsLabel(bill) {
      const installmentsLabel = bill?.payments.map(payment => payment.installments.length > 1 ? `Parcelado em ${payment.installments.length}x` : 'À vista').filter( function( item, index, inputArray ) {
           return inputArray.indexOf(item) === index;
    }).join(', ')
      
      return installmentsLabel
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

th,
td {
  border: none !important;
  vertical-align: middle !important;
  .blue-text {
    font-weight: 700;
    color: var(--blue-500);
  }

  .icon {
    width: 20px;
    height: 20px;
  }
  .eye-icon {
    color: #305bf2;
  }

  .plus-icon {
    width: 24px;
    height: 24px;
    fill: var(--states-success);
  }

  .minus-icon {
    fill: var(--states-error);
  }
}
</style>
